import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
  } from "./types";

import AuthService from "../services/auth.service";
export const register = (first_name, email_id,mobile_no, password, gender) => (dispatch) => {
  return AuthService.register(first_name, email_id,mobile_no, password, gender).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return response.data.data;
    },
    (error) => {
      const message = (error.response &&
          error.response.data.data && 
          error.response.data.data.message) || 
          error.response.data.data[0].first_name || 
          error.response.data.data[0].last_name ||
          error.response.data.data[0].email_id || 
          error.response.data.data[0].mobile_no ||
          error.response.data.data[0].password ||
        error.message || 
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (mobile_no, password) => (dispatch) => {
  return AuthService.login(mobile_no, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};
export const google_login = (google_id, name, email) => (dispatch) => {
  return AuthService.google_login(google_id, name, email).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};
export const facebook_login = (facebook_id, name, email) => (dispatch) => {
  return AuthService.facebook_login(facebook_id, name, email).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const forgot_password = (mobile_no, email) => (dispatch) => {
    return AuthService.forgot_password(mobile_no, email).then(
        (data) => {
            /*dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: data },
            });*/

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: LOGIN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};
export const logout = () => (dispatch) => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });

};