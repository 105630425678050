import React, { useState} from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import { Row, Col, Form, Button } from 'react-bootstrap'
import { ErrorMessage, Field, Formik } from 'formik';
import { changePassword } from './commonValidation';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import useAxiosInstance from '../../Api/AxiosInstance';



function ChangePassword() {
    const user = JSON.parse(localStorage.getItem("user"));
    const [error, setError] = useState('')
    const [oldpasswordType, setOldpasswordType] = useState("password");
    const [confirmpasswordType, setconfirmpasswordType] = useState("password");
    const [newpasswordType, setNewpasswordType] = useState("password");
    const AxiosInstance = useAxiosInstance()


    const togglePassword = (id) => {
        if (id == "oldpassword") {
            if (oldpasswordType === "password") {
                setOldpasswordType("text");
                return true;
            }
            setOldpasswordType("password");
            return true;
        }
        else if (id == "confirmpassword") {
            if (confirmpasswordType === "password") {
                setconfirmpasswordType("text");
                return true;
            }
            setconfirmpasswordType("password");
            return true;
        } else {
            if (newpasswordType === "password") {
                setNewpasswordType("text");
                return true;
            }
            setNewpasswordType("password");
            return true;
        }

    };



    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            
            const response = await AxiosInstance.post( 'change-password', values)
            if (response.data.success == "1") {
                toast.success('Password Update Successfully', {
                    position: "top-right",
                    autoClose: 1000,
                });
            }

        } catch (error) {
            setError(error.response.data.message)

        } finally {
            setSubmitting(false);
        }
    }

    return (
        <div className='main-page bg4'>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <DashboardHeader />
            <DashboardSidebar />

            <section className='main-section'>
                <div className='main-inner'>

                    <DashboardTitle />

                    <div className='edit-form-main'>
                        <Row className='dashboard-blocks'>

                            <Col xs={12} md={8} lg={8} className='left-block'>
                                <div className='white_box profile-form-block'>

                                    <div className='box-title'>
                                        <h3>Change Password</h3>
                                    </div>

                                    <Formik
                                        initialValues={{
                                            current_password: '',
                                            password: '',
                                            confirm_password: ''
                                        }}
                                        validationSchema={changePassword}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ handleSubmit, handleChange, values, isSubmitting }) => (
                                            <Form onSubmit={handleSubmit} >
                                                <div className='form-inner d-flex'>

                                                    <Form.Group className='input-field'>
                                                        <label className='label'>Old Password</label>
                                                        <div className="input-icon-block relative">
                                                            <Field name='current_password' type={oldpasswordType} value={values.current_password}  onChange={handleChange} placeholder='Enter Old Password' onInput={(e) => { e.target.value = e.target.value.slice(0, 20); }} />
                                                            <ErrorMessage name="current_password" >
                                                                {msg => (
                                                                    <p className="mt-2" style={{ color: "red" }}>{msg}</p>
                                                                )}
                                                            </ErrorMessage>
                                                            {error && <p className="mt-2" style={{ color: "red" }}>{error}</p>}
                                                            <div className="ped-inn" onClick={() => togglePassword("oldpassword")}>
                                                                {oldpasswordType === "password" ? (
                                                                    <AiOutlineEye />


                                                                ) : (
                                                                    <AiOutlineEyeInvisible />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Form.Group>


                                                    <Form.Group className='input-field'>
                                                        <label className='label'>New Password</label>
                                                        <div className="input-icon-block relative">
                                                            <Field name='password' type={newpasswordType} value={values.password} onChange={handleChange} placeholder='Enter Password' onInput={(e) => { e.target.value = e.target.value.slice(0, 20); }} />
                                                            <ErrorMessage name="password" >
                                                                {msg => (
                                                                    <p className="mt-2" style={{ color: "red" }}>{msg}</p>
                                                                )}
                                                            </ErrorMessage>
                                                            <div className="ped-inn" onClick={() => togglePassword("newpassword")}>
                                                                {newpasswordType === "password" ? (
                                                                    <AiOutlineEye />

                                                                ) : (
                                                                    <AiOutlineEyeInvisible />
                                                                )}
                                                            </div>
                                                        </div>

                                                    </Form.Group>

                                                    <Form.Group className='input-field'>
                                                        <label className='label'>Confirm New Password</label>
                                                        <div className="input-icon-block relative">
                                                            <Field name='confirm_password' type={confirmpasswordType} value={values.confirm_password} onChange={handleChange} placeholder='Enter Confirm Password' onInput={(e) => { e.target.value = e.target.value.slice(0, 20); }} />
                                                            <ErrorMessage name="confirm_password" >
                                                                {msg => (
                                                                    <p className="mt-2" style={{ color: "red" }}>{msg}</p>
                                                                )}
                                                            </ErrorMessage>
                                                            <div className="ped-inn" onClick={() => togglePassword("confirmpassword")}>
                                                                {confirmpasswordType === "password" ? (
                                                                    <AiOutlineEye />

                                                                ) : (

                                                                    <AiOutlineEyeInvisible />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Form.Group>

                                                    <Form.Group className='input-field full-width form-btnbox d-flex mb-0 pt-2'>
                                                        <Button className='btn secondary ml-auto' variant="primary" type="submit">Change Password</Button>
                                                    </Form.Group>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>

                                </div>
                            </Col>


                        </Row>
                    </div>

                </div>
            </section>
        </div>
    )
}


export default ChangePassword