import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PrescriptionModal from '../../include/PrescriptionModal';

function DashboardTitle() {
  return (
    <div className='dash-title-block hide'>
      <div className='dash-title-block-inn d-flex v-center'>
        <h4 className='mb-0'><Link to='/dashboard'>My Dashboard</Link></h4>

        <div className='tl-btnbox ml-auto'>
          <PrescriptionModal />
        </div>

      </div>
    </div>
  )
}
export default DashboardTitle