import React from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import { Link} from 'react-router-dom';
import { ToastContainer} from 'react-toastify'
import ProductData from './ProductData'

function Shop() {

  return (
    <div className='main-page bg4'>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <DashboardHeader />
      <DashboardSidebar />
      <section className='main-section'>
        <div className='main-inner'>
          <DashboardTitle />
          <div className='shop-page-main'>
            <div className='breadcrumb-block ptb-20 pt-0'>
              <ul className='breadcrumb-list d-flex v-center'>
                <li><Link to='/dashboard'>Home</Link></li>
                <li>Order medicine</li>
              </ul>
            </div>
            <ProductData/>
          </div>
        </div>
      </section>
    </div>
  )
}
export default Shop