// AuthContext.js
import React, { createContext, useContext, useState  } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);
const userData = JSON.parse(localStorage.getItem('user'));

export const getToken = () => {
  return userData?.access_token ; // Provide a default value if no token is found
};


export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(userData?.user); 
  const [user, setUser] = useState(userData?.user); 

  const handleLogin = () => {
    setUser(userData?.user);
    setLoggedIn(true); 
  };

  const handleLogout = () => { 
    setLoggedIn(false);
  };

  
  return (
    <AuthContext.Provider value={{ loggedIn, handleLogin, handleLogout, user, getToken,  }}>
      {children}
    </AuthContext.Provider>
  );
};
