import React from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import thankyou_icon from '../../assets/images/thankyou_icon.svg';
import {useLocation} from 'react-router-dom';

import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap'

function Thankyou() {
  const location = useLocation();

  return (
    <div className='main-page bg4'>
      <DashboardHeader />
      <DashboardSidebar />

      <section className='main-section'>
        <div className='main-inner'>

          <DashboardTitle />

          <div className='thankyou-page'>
            <div className='white_box thankyou-inn'>
              <div className='thankyou-innbox'>

                <div className='block-title text-center'>
                  <i className='thankyou-iconbox d-flex'><img src={thankyou_icon} alt='' /></i>
                  <h1 className='h2'>Thank You</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
                {location.state != null &&
                  <div className='table-block entry-content'>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Location Name</td>
                        <td>{location.state.clinic_name}</td>
                      </tr>
                      <tr>
                        <td>Address</td>
                        <td>{location.state.clinic_address}</td>
                      </tr>
                      <tr>
                        <td>Map Link</td>
                        <td>
                          <Link to='#/' target='_blank'>https://www.google.com/maps/search/935P,+Sector+51,+Opp+to+Amity+International+School+Gurugram,+Haryana,+122018,+India/@28.4337068,77.0608233,17z/data=!3m1!4b1?entry=ttu</Link>
                        </td>
                      </tr>
                      <tr>
                        <td>Appointment Date</td>
                        <td>{location.state.schedule_date}</td>
                      </tr>
                      <tr>
                        <td>Appointment Time</td>
                        <td>{location.state.schedule_from_time} - {location.state.schedule_to_time}</td>
                      </tr>
                    </tbody>
                  </Table>

                </div>
                }
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  )
}
export default Thankyou