import React, { useState, useCallback } from 'react';
import { Modal } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import thankyou_icon from '../../assets/images/thankyou_icon.svg';
import { useDispatch, useStore } from 'react-redux';
import { logout } from '../../actions/auth';
import useRazorpay from 'react-razorpay';
import useAxiosInstance from '../../Api/AxiosInstance';

function PrescriptionModal() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [razorpay, isLoaded] = useRazorpay();
  const [orderId, setOrderId] = useState('')
  const dispatch = useDispatch();
  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);
  const { getState } = useStore((state) => state);
  const AxiosInstance = useAxiosInstance()

  //Upload File
  const [file1, setFile1] = useState();
  const [myclass1, changeclass1] = useState("");
  const navigate = useNavigate()
  const date = new Date()
  const formattedDate = date.toISOString().slice(0, 10);

  const handlaePament = async () => {

    try {
      const orderDetailsResponse = await AxiosInstance.post("appointment/add", { clinic_id: 1, schedule_date: formattedDate, })
      const checkedorderId = orderDetailsResponse.data.data.appointment.id;

      const response = await AxiosInstance.post("create-order-id-in-razorpay", { amount: 1000 });
      if (response.data.status === 400 && response.data.message === "Token is expired!!") {
        logOut();
        navigate("/login");
      } else {
        if (response.data.status === 200) {
          setOrderId(response.data.data.order_id)
          const razorpay_key = process.env.RAZORPAY_KEY_ID;
          const options = {
            key: 'rzp_test_3PYKIWLbUWhl67',
            accept_partial: false,
            amount: Math.round(1000 * 100),
            currency: "INR",
            name: "Eugenix",
            description: "Test Transaction",
            image: "http://localhost/euginix_crm_backend/assets/front/images/logo.png",
            order_id: orderId,
            handler: async (res) => {
              if (res.razorpay_payment_id) {
                const response = await AxiosInstance.post("add-payment-details", { table_id: checkedorderId, type_id: 3, payment_id: res.razorpay_payment_id, amount: 1000 })
                if (response.data.status === 400 && response.data.message === "Token is expired!!") {
                  logOut();
                  navigate("/login");
                } else {
                  if (response.data.status == 200) {
                    window.location.reload()
                  }
                }
              }
            },
            notes: {
              permanent_address: "Razorpay Corporate Office",
            },
            theme: {
              color: "#3399cc",
            },
          };

          const rzpay = new razorpay(options);
          rzpay.open();
        }
      }
    } catch (error) {

    }

  }

  return (
    <>
      <Link className='btn btn2 small' onClick={handleShow} to=''>Request a Prescription</Link>

      <Modal id="modalPrescription" show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className='appoint-booking-body ptb-20 pb-0 text-center'>
            <p className='text-20'>Are you sure want to request a prescription?</p>
            <p className='text-20'>
              It will cost you ₹ 1000
            </p>
            <div className='btnbox ptb-20 pb-0 d-flex'>
              <div className='btn-items' onClick={handleClose}>
                <Link className='btn primary' onClick={handlaePament} to=''>YES</Link>
              </div>
              <div className='btn-items'>
                <Link className='btn secondary' onClick={handleClose} to=''>NO</Link>
              </div>

            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal id="modalThankyou" show={show3} onHide={handleClose3} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className='appoint-booking-body text-center thankyou-inn'>
            <i className='thankyou-iconbox d-flex'><img src={thankyou_icon} alt='' width='44' /></i>
            <h3 className='mb-2'><strong>Thank You!</strong></h3>
            <p className='mb-0'>Thank you for your submission.</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default PrescriptionModal