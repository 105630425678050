import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import logo from '../../assets/images/site-logo-yellow.png';
import register_img from '../../assets/images/register-img.png';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { facebook_login, google_login, register } from "../../actions/auth";
import { Formik, Field, ErrorMessage } from 'formik';
import { registerScema } from './commonValidation';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

var SCOPES = "https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/gmail.send";
const apiKey = process.env.GOOGLE_API;

function Register() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { message } = useSelector(state => state.message);
    const [emailError, setEmailError] = useState()
    const [mobileError, setMobileError] = useState()
    const [passwordType, setPasswordType] = useState("password");
    const [confirmpasswordType, setconfirmpasswordType] = useState("password");
    const [registationmsg, setRegistrationmsg] = useState(false)

    const togglePassword = (id) => {
        if (id == "password") {
            if (passwordType === "password") {
                setPasswordType("text");
                return true;
            }
            setPasswordType("password");
            return true;
        } else {
            if (confirmpasswordType === "password") {
                setconfirmpasswordType("text");
                return true;
            }
            setconfirmpasswordType("password");
            return true;
        }

    };

    const handleRegister = (values, action) => {
        setLoading(true);
        dispatch(register(values.full_name, values.email_id, values.mobile_no, values.password, 1))
            .then((response) => {
                setRegistrationmsg(true)
                setTimeout(() => {
                    setRegistrationmsg(false)
                    navigate('/login');
                }, 15000);

            })
            .catch((err) => {
                setLoading(false);
                console.log(err)


                if (message) {
                    const validationErrors = message;
                    if (validationErrors.email_id) {
                        setEmailError(validationErrors.email_id[0]);
                    } else {
                        setEmailError("");
                    }

                    if (validationErrors.mobile_no) {
                        setMobileError(validationErrors.mobile_no[0]);
                    } else {
                        setMobileError("");
                    }
                }
                // action.resetForm()
            });
    };

    return (
        <div>
            <section className="login-section">
                <div className='circle-top-left'></div>
                <div className='circle-bottom-left'></div>
                <div className='pattern-top-center'></div>
                <div className='pattern-top-right'></div>
                <div className='pattern-bottom-right'></div>
                <div className='pattern-bottom-right'></div>
                <Container className='relative'>
                    <Row className='login-inner v-center ptb-100'>
                        <Col xs={12} md={12} lg={6} className='left-block'>
                            <div className='login-img-wrapper'>
                                <div className='logo-block text-center ptb-30 pt-0'>
                                    <img src={logo} alt='' />
                                </div>
                                <div className='login-imgbox'>
                                    <img src={register_img} alt='' />
                                </div>
                                <div className='glow-box'></div>
                            </div>
                        </Col>

                        <Col xs={12} md={12} lg={6} className='right-block d-flex'>
                            <div className='form-wrapper ml-auto mr-auto'>
                                <div className='logo-block text-center ptb-30 hide show_mobi_view'>
                                    <img src={logo} alt='' />
                                </div>
                                <h2 className='tl'>Create a New Account</h2>
                                <Formik
                                    initialValues={{
                                        full_name: '',
                                        email_id: '',
                                        mobile_no: '',
                                        gender: '',
                                        password: '',
                                        confirm_password: '',
                                        termsandConditions: false,
                                    }}
                                    validationSchema={registerScema}
                                    onSubmit={handleRegister}
                                >
                                    {({ handleSubmit, handleChange, values, touched }) => (
                                        <form onSubmit={handleSubmit} className="form-inner d-flex">

                                            <div className="input-field full-width">
                                                <label className="label">Full Name <em className='required'>*</em></label>
                                                <Field
                                                    type="text"
                                                    name="full_name"
                                                    placeholder="Enter Full Name"
                                                    value={values.full_name}
                                                    onChange={handleChange}
                                                />
                                                <ErrorMessage name="full_name" component="div" className="alert alert-danger mt-2" />
                                            </div>


                                            <div className='input-field full-width'>
                                                <label className='label'>Email ID <em className='required'>*</em></label>
                                                <Field type='email' placeholder='Enter Email ID' name='email_id'
                                                    value={values.email_id}
                                                    onChange={handleChange}
                                                />
                                                <ErrorMessage name="email_id" component="div" className="alert alert-danger mt-2" />
                                            </div>

                                            {message?.email_id ?
                                                <div className="input-field full-width">
                                                    <div className="alert alert-danger mt-2" role="alert">
                                                        {message.email_id}
                                                    </div>
                                                </div>

                                                : ''}

                                            <div className='input-field full-width'>
                                                <label className='label'>Gender <em className='required'>*</em></label>
                                                <div className="option-block d-flex v-center">
                                                    <label className="radiobox">
                                                        <Field type="radio" name="gender" value="male" checked={values.gender === 'male'} onChange={handleChange} />
                                                        <span className="checkmark"></span> Male
                                                    </label>
                                                    <label className="radiobox">
                                                        <Field type="radio" name="gender" value="female" checked={values.gender === 'female'} onChange={handleChange} />
                                                        <span className="checkmark"></span> Female
                                                    </label>
                                                </div>
                                                <ErrorMessage name="gender" component="div" className="alert alert-danger mt-2" />
                                            </div>



                                            <div className="input-field full-width">
                                                <label className="label">Mobile No. <em className='required'>*</em></label>
                                                <Field
                                                    type="text"
                                                    inputMode="numeric"
                                                    name="mobile_no"
                                                    placeholder="Enter Mobile No."
                                                    value={values.mobile_no}
                                                    onChange={handleChange}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/\D/g, '');
                                                        e.target.value = e.target.value.slice(0, 10);
                                                    }}
                                                />
                                                <ErrorMessage name="mobile_no" component="div" className="alert alert-danger mt-2" />
                                            </div>
                                            {/* <p style={{color:'red'}}>{mobileError}</p> */}
                                            {message?.mobile_no ?
                                                <div className="input-field full-width">
                                                    <div className="alert alert-danger mt-2" role="alert">
                                                        {message.mobile_no}
                                                    </div>
                                                </div>
                                                : ''}


                                            <div className="input-field full-width">
                                                <label className="label">Password <em className='required'>*</em></label>
                                                <div className="input-icon-block relative">
                                                    <Field
                                                        type={passwordType}
                                                        name="password"
                                                        placeholder="Enter Password"
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        onInput={(e) => { e.target.value = e.target.value.slice(0, 20); }}
                                                    />
                                                    <ErrorMessage name="password" component="div" className="alert alert-danger mt-2" />
                                                    <div className="ped-inn" onClick={() => togglePassword("password")}>
                                                        {/* <AiOutlineEye />
                          <AiOutlineEyeInvisible /> */}
                                                        {passwordType === "password" ? (

                                                            <AiOutlineEye />

                                                        ) : (
                                                            <AiOutlineEyeInvisible />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="input-field full-width">
                                                <label className="label">Confirm Password <em className='required'>*</em></label>
                                                <div className="input-icon-block relative">
                                                    <Field
                                                        // type={passwordType} confirm_password
                                                        type={confirmpasswordType}
                                                        name="confirm_password"
                                                        placeholder="Enter Confirm Password"
                                                        value={values.confirm_password}
                                                        onChange={handleChange}
                                                        onInput={(e) => { e.target.value = e.target.value.slice(0, 20); }}
                                                    />
                                                    <ErrorMessage name="confirm_password" component="div" className="alert alert-danger mt-2" />
                                                    <div className="ped-inn" onClick={() => togglePassword("confirm_password")}>

                                                        {confirmpasswordType === "password" ? (
                                                            <AiOutlineEye />

                                                        ) : (
                                                            <AiOutlineEyeInvisible />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>


                                            <div className='input-field full-width'>
                                                <div className='term-agree-block d-flex v-center'>
                                                    <label className='checkbox'>
                                                        <input type='checkbox' className="required" value={values.termsandConditions} name="termsandConditions" onChange={handleChange} />
                                                        <span className='checkmark'></span>
                                                        I agree with all  </label>  <Link className='forgot-link ml-2' to='#'><u>Terms & Conditions</u></Link>
                                                </div>
                                                <ErrorMessage name="termsandConditions" component="div" className="alert alert-danger mt-2" />
                                            </div>


                                            <div className="input-field full-width form-btnbox mb-0">
                                                <Button className="btn secondary w-100" variant="primary" type="submit" >
                                                    Register
                                                </Button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>

                                <div className='link-box'>
                                    <p >You have an account? <Link to='/login'>Login to your account</Link></p>
                                </div>
                                {
                                    registationmsg &&
                                    <div className="mt-2 alert alert-success" role="alert">
                                        Thank you for registering with us.
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
}

export default Register;
