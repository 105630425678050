import React, { useState, useEffect, useCallback } from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";
import useAxiosInstance from '../../Api/AxiosInstance';

function ApplyEMI() {
  const dispatch = useDispatch();
  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const [MobileError, setMobileError] = useState('');
  const [BankError, setBankError] = useState('');
  const [AdharError, setAdharError] = useState('');
  const [PanError, SetPanError] = useState('');
  const [ChequeError, setChequeError] = useState('');
  const [adhar_card_files, setAdharCardFiles] = useState([]);
  const [blob_adhar_card_files, setBlobAdharCardFiles] = useState([]);
  const [adhar_file_types, setAdharFileTypes] = useState([]);
  const [file, setFile] = useState('')
  const [mobile_no, setMobileNo] = useState("");
  const [bank_id, setBankId] = useState("");
  const [items, setItems] = useState([]);
  const [checkbox, setChekbox] = useState(false);
  const [CheckboxError, setChekboxError] = useState('');
  const [myclass1, changeclass1] = useState("");
  const AxiosInstance = useAxiosInstance()

  let navigate = useNavigate();


  function handleChange1(e) {
    setAdharError('');
    const files = e.target.files;
    const newFiles = Array.from(files);
    const fileTypes = newFiles?.map(file => file.type);
    const fileTYpe = Array.from(files)?.map(file => file.type);
    const validTypes = fileTypes.every(type => type === "image/jpeg" || type === "image/png" || type === "application/pdf");

    const existingPDFs = adhar_file_types.filter(type => type === "application/pdf").length;
    const existingImages = adhar_file_types.filter(type => type === "image/jpeg" || type === "image/png").length;

    const newPDFs = fileTypes.filter(type => type === "application/pdf").length;
    const newImages = fileTypes.filter(type => type === "image/jpeg" || type === "image/png").length;
    if (!validTypes) {
      setAdharError("Only JPEG, PNG, and PDF files are allowed.");
      return;
    }

    const totalPDFs = existingPDFs + newPDFs;
    const totalImages = existingImages + newImages;

    if ((totalPDFs > 0 && totalImages > 0) || newPDFs > 1 || totalImages > 2) {
      setAdharError("You can upload only one PDF or two images (JPEG, PNG), but not both.");
      return;
    }

    const blobFiles = newFiles?.map(file => URL.createObjectURL(file));
    if (fileTYpe[0] == "application/pdf") {
      setFile(newFiles[0].name)
      setAdharCardFiles(prevFiles => [...prevFiles, ...newFiles]);
      setBlobAdharCardFiles(prevBlobs => [...prevBlobs, ...blobFiles]);
      setAdharFileTypes(prevTypes => [...prevTypes, ...fileTypes]);
      changeclass1('file-uploaded')
    } else {
      const blobFiles = newFiles?.map(file => URL.createObjectURL(file));
      setFile(newFiles[0].name)
      setAdharCardFiles(prevFiles => [...prevFiles, ...newFiles]);
      setBlobAdharCardFiles(prevBlobs => [...prevBlobs, ...blobFiles]);
      setAdharFileTypes(prevTypes => [...prevTypes, ...fileTypes]);
      if (totalImages == 2) {
        changeclass1('file-uploaded')
      }
    }
  }

  const handleRemoveAdhar = (index) => {
    changeclass1('')
    setAdharCardFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setBlobAdharCardFiles((prevBlobs) => prevBlobs.filter((_, i) => i !== index));
    setAdharFileTypes((prevTypes) => prevTypes.filter((_, i) => i !== index));
  };


  const [pan_files, setPanFiles] = useState([]);
  const [blob_pan_files, setBlobPanFiles] = useState([]);
  const [file1, setFile1] = useState('')
  const [class2, setClass2] = useState('')
  const [pan_file_types, setPanFileType] = useState([])


  function handleChange2(e) {
    SetPanError('');
    const files = e.target.files;
    const newFiles = Array.from(files);
    const fileTypes = newFiles?.map(file => file.type);
    const fileTYpe = Array.from(files)?.map(file => file.type);
    const validTypes = fileTypes.every(type => type === "image/jpeg" || type === "image/png" || type === "application/pdf");


    if (!validTypes) {
      SetPanError("Only JPEG, PNG, and PDF files are allowed.");
      return;
    }

    const blobFiles = newFiles?.map(file => URL.createObjectURL(file));
    if (fileTYpe[0] == "application/pdf") {
      setFile1(newFiles[0].name)
      setPanFiles(prevFiles => [...prevFiles, ...newFiles]);
      setBlobPanFiles(prevBlobs => [...prevBlobs, ...blobFiles]);
      setPanFileType(prevTypes => [...prevTypes, ...fileTypes]);
      setClass2('file-uploaded')
    } else {
      const blobFiles = newFiles?.map(file => URL.createObjectURL(file));
      setFile1(newFiles[0].name)
      setPanFiles(prevFiles => [...prevFiles, ...newFiles]);
      setBlobPanFiles(prevBlobs => [...prevBlobs, ...blobFiles]);
      setPanFileType(prevTypes => [...prevTypes, ...fileTypes]);
      setClass2('file-uploaded')
    }
  }
  function handleRemovePan(index) {
    setPanFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    setBlobPanFiles(prevBlobs => prevBlobs.filter((_, i) => i !== index));
    setPanFileType(prevTypes => prevTypes.filter((_, i) => i !== index));
    setClass2('')
  }

  const [cancel_cheque_files, setCancelChequeFiles] = useState([]);
  const [blob_cancel_cheque_files, setBlobCancelChequeFiles] = useState([]);
  const [file2, setFile2] = useState('')
  const [class3, setClass3] = useState('')
  const [cheque_file_types, setChequeFileType] = useState([])

  function handleChange3(e) {
    setChequeError('');
    const files = e.target.files;
    const newFiles = Array.from(files);
    const fileTypes = newFiles?.map(file => file.type);
    const fileTYpe = Array.from(files)?.map(file => file.type);
    const validTypes = fileTypes.every(type => type === "image/jpeg" || type === "image/png" || type === "application/pdf");

    if (!validTypes) {
      setChequeError("Only JPEG, PNG, and PDF files are allowed.");
      return;
    }
    setClass3('file-uploaded')


    const blobFiles = newFiles?.map(file => URL.createObjectURL(file));
    if (fileTYpe[0] == "application/pdf") {

      setFile2(newFiles[0].name)
      setCancelChequeFiles(prevFiles => [...prevFiles, ...newFiles]);
      setBlobCancelChequeFiles(prevBlobs => [...prevBlobs, ...blobFiles]);
      setChequeFileType(prevTypes => [...prevTypes, ...fileTypes]);
      setClass3('file-uploaded')
    } else {
      const blobFiles = newFiles?.map(file => URL.createObjectURL(file));
      setFile2(newFiles[0].name)
      setCancelChequeFiles(prevFiles => [...prevFiles, ...newFiles]);
      setBlobCancelChequeFiles(prevBlobs => [...prevBlobs, ...blobFiles]);
      setChequeFileType(prevTypes => [...prevTypes, ...fileTypes]);
      setClass3('file-uploaded')
    }
  }

  function handleRemoveCheque(index) {
    setCancelChequeFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    setBlobCancelChequeFiles(prevBlobs => prevBlobs.filter((_, i) => i !== index));
    setChequeFileType(prevTypes => prevTypes.filter((_, i) => i !== index));
    setClass3('')
  }



  useEffect(() => {
    const fetchBank = async () => {
      try {
        const response = await AxiosInstance.get('banks')
        setItems(response.data.data);
      } catch (error) {

      }
    }
fetchBank()
  }, []);

  const validation = () => {
    let isValid = true;
    if (!mobile_no) {
      setMobileError('Mobile No. is required');
      isValid = false;
    } else if (mobile_no.length !== 10) {
      setMobileError('Mobile No. must be 10 digits');
      isValid = false;
    } else {
      setMobileError('');
    }

    if (pan_files.length === 0) {
      SetPanError("Pan Card is required");
      isValid = false;
    } else {
      SetPanError('');
    }

    if (adhar_card_files.length === 0) {
      setAdharError("Adhar Card is required");
      isValid = false;
    }

    if (cancel_cheque_files.length === 0) {
      setChequeError("Cancel Cheque is required");
      isValid = false;
    } else {
      setChequeError('');
    }

    if (!bank_id) {
      setBankError("Bank is required");
      isValid = false;
    } else {
      setBankError('');
    }

    if (!checkbox) {
      setChekboxError('You must agree to the terms and conditions');
      isValid = false;
    } else {
      setChekboxError('');
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validation()) {
      const totalPDFs = adhar_file_types.filter(type => type === "application/pdf").length;
      const totalImages = adhar_file_types.filter(type => type === "image/jpeg" || type === "image/png").length;

      if (totalImages && totalImages < 2) {
        setAdharError("You must upload exactly two images (JPEG, PNG).");
        return;
      }

      if (totalPDFs && totalPDFs !== 1) {
        setAdharError("You must upload exactly one PDF.");
        return;
      }

     
      const formData = new FormData();
      cancel_cheque_files.forEach(file => formData.append("cancel_cheque_file", file));
      adhar_card_files.forEach((file , index) => formData.append(`adhar_card_file`, file));
      pan_files.forEach(file => formData.append("pan_file", file));
      formData.append("bank_id", bank_id);
      formData.append("mobile_no", mobile_no);
      try {
        const response = await AxiosInstance.post('user/emi/option/add' , formData)
        if (response.status === 400 && response.message === "Token is expired!!") {
          logOut();
          navigate("/login");
          window.location.reload();
        } else {
          if (response.status === 200) {
            navigate("/emi-thankyou");
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  };

  const handleChange = event => {
    setBankId(event.target.value);
    setBankError('');
  };

  const onChangeMobileNo = event => {
    setMobileNo(event.target.value);
    setMobileError('');
  };

  return (
    <div className='main-page bg4'>
      <DashboardHeader />
      <DashboardSidebar />

      <section className='main-section'>
        <div className='main-inner'>
          <DashboardTitle />

          <div className='apply-page'>
            <div className='breadcrumb-block ptb-20 pt-0'>
              <ul className='breadcrumb-list d-flex v-center'>
                <li><Link to='/dashboard'>Home</Link></li>
                <li>EMI form</li>
              </ul>
            </div>

            <div className='white_box aplly-emi-block'>
              <div className='box-title'>
                <h3>Easy Apply for EMI</h3>
              </div>

              <div className='emi-form-block'>
                <Row>
                  <Col xs={12} md={8} lg={5} className='items'>
                    <Form className='form-inner emi-form-main' onSubmit={handleSubmit}>
                      <div className='input-field full-width'>
                        <label className='label'>Select Bank <em className="required">*</em></label>
                        <select aria-label="select" name="bank_id" value={bank_id} onChange={handleChange}>
                          <option value="">Select Bank Account</option>
                          {items?.map((data, idx) => (
                            <option key={idx} value={data.id}>{data.name}</option>
                          ))}
                        </select>
                        {BankError && (
                          <div className="mt-2 mb-2 alert alert-danger" role="alert">
                            {BankError}
                          </div>
                        )}
                      </div>

                      <div className='input-field full-width'>
                        <label className='label'>Register Mobile No. <em className="required">*</em></label>
                        <Input
                          type='number'
                          className="form-control"
                          name="password"
                          value={mobile_no}
                          maxLength={10}
                          placeholder="Enter Mobile No."
                          onChange={onChangeMobileNo}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '');
                            e.target.value = e.target.value.slice(0, 10);
                          }}
                        />
                        {MobileError && (
                          <div className="mt-2 mb-2 alert alert-danger" role="alert">
                            {MobileError}
                          </div>
                        )}
                      </div>

                      <div className='input-field full-width'>
                        <label className='label'>Upload Aadhar Card <em className="required">*</em></label>
                        <div className={`file-upload-box ${myclass1}`}>
                          <label className='upload-img1 w-100'>
                            <div className='drag-drop-box d-flex'>
                              <i className="las la-image"></i><span>Drag & Drop or Choose file to upload</span>
                            </div>
                            <Input type="file" name="adhar_card_file" onChange={handleChange1} multiple="multiple" disabled={myclass1 ? true : false} />
                          </label>
                        </div>
                        {
                          adhar_file_types == "application/pdf" ?
                            <div className='uploaded-files mt-2' title={file}>
                              <i className="las la-file"></i>
                              <span>{file}</span>
                              <i onClick={() => handleRemoveAdhar(0)} className="fa fa-trash" aria-hidden="true"></i>
                            </div>
                            :
                            <div className='upload-imgs d-flex'>
                              {blob_adhar_card_files?.map((blob, index) => (
                                <div className='imgbox' key={index}>
                                  <i onClick={() => handleRemoveAdhar(index)} className="fa fa-times" aria-hidden="true"></i>
                                  <img src={blob} alt="Aadhar" />
                                </div>
                              ))}
                            </div>
                        }

                        {AdharError && (
                          <div className="alert alert-danger mt-2" role="alert">
                            {AdharError}
                          </div>
                        )}

                      </div>

                      <div className='input-field full-width'>
                        <label className='label'>Upload PAN Card <em className="required">*</em></label>
                        <div className={`file-upload-box ${class2}`}>
                          <label className='upload-img1 w-100'>
                            <div className='drag-drop-box d-flex'>
                              <i className="las la-image"></i><span>Drag & Drop or Choose file to upload</span>
                            </div>
                            <Input type="file" name="pan_file" onChange={handleChange2} disabled={class2 ? true : false} />
                          </label>
                        </div>
                        {
                          pan_file_types == "application/pdf" ?
                            <div className='uploaded-files mt-2' title={file1}>
                              <i className="las la-file"></i>
                              <span>{file1}</span>
                              <i onClick={() => handleRemovePan(0)} className="fa fa-trash" aria-hidden="true"></i>
                            </div>
                            :
                            <div className='upload-imgs d-flex'>
                              {blob_pan_files?.map((blob, index) => (
                                <div className='imgbox' key={index}>
                                  <i onClick={() => handleRemovePan(index)} className="fa fa-times" aria-hidden="true"></i>
                                  <img src={blob} alt="Aadhar" />
                                </div>
                              ))}
                            </div>
                        }

                        {PanError && (
                          <div className="alert alert-danger mt-2" role="alert">
                            {PanError}
                          </div>
                        )}

                      </div>

                      <div className='input-field full-width'>
                        <label className='label'>Upload Cancel Cheque <em className="required">*</em></label>
                        <div className={`file-upload-box ${class3}`}>
                          <label className='upload-img1 w-100'>
                            <div className='drag-drop-box d-flex'>
                              <i className="las la-image"></i><span>Drag & Drop or Choose file to upload</span>
                            </div>
                            <Input type="file" name="cancel_cheque_file" onChange={handleChange3} disabled={class3 ? true : false} />
                          </label>
                        </div>
                        {
                          cheque_file_types == "application/pdf" ?
                            <div className='uploaded-files mt-2' title={file2}>
                              <i className="las la-file"></i>
                              <span>{file2}</span>
                              <i onClick={() => handleRemoveCheque(0)} className="fa fa-trash" aria-hidden="true"></i>
                            </div>
                            :
                            <div className='upload-imgs d-flex'>
                              {blob_cancel_cheque_files?.map((blob, index) => (
                                <div className='imgbox' key={index}>
                                  <i onClick={() => handleRemoveCheque(index)} className="fa fa-times" aria-hidden="true"></i>
                                  <img src={blob} alt="Aadhar" />
                                </div>
                              ))}
                            </div>
                        }

                        {ChequeError && (
                          <div className="alert alert-danger mt-2" role="alert">
                            {ChequeError}
                          </div>
                        )}

                      </div>

                      <div className='input-field full-width'>
                        <div className='term-agree-block d-flex v-center'>
                          <label className='checkbox'>
                            <input type='checkbox' onChange={() => { setChekbox(!checkbox); setChekboxError('') }} /><span className='checkmark'></span> I agree with all </label>
                          <Link className='forgot-link ml-2' to='#'><u>Terms & Conditions</u></Link>
                        </div>
                        {CheckboxError && (
                          <div className="mt-3 mb-2 alert alert-danger" role="alert">
                            {CheckboxError}
                          </div>
                        )}
                      </div>

                      <div className='input-field full-width form-btnbox pt-2 mb-0'>
                        <button className='btn secondary mw_200' type='submit'>Apply for an EMI</button>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  );
}

export default ApplyEMI;