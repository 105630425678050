import React from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import thankyou_icon from '../../assets/images/thanks_shapes_y.svg';

function Thankyou() {
  return (
    <div className='main-page bg4'>
      <DashboardHeader />
      <DashboardSidebar />

      <section className='main-section'>
        <div className='main-inner'>

          <DashboardTitle />

          <div className='thankyou-page'>
            <div className='white_box thankyou-inn'>
              <div className='thankyou-innbox'>

                <div className='block-title text-center'>
                  <i className='thankyou-iconbox d-flex'><img src={thankyou_icon} alt='' /></i>
                  <h1 className='h2'>Thank You</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>

              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  )
}
export default Thankyou