import React, { useState, useEffect } from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Table } from 'react-bootstrap'
import Pagination from 'react-bootstrap/Pagination';
import configData from "../../config.json";
import useAxiosInstance from '../../Api/AxiosInstance';

function MyAccount() {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(configData.DATA_PER_PAGE);
  const [totalItems, setTotalItems] = useState(0);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState("Loading...");
  const AxiosInstance = useAxiosInstance()

  let navigate = useNavigate();

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await AxiosInstance.post('my-orders')
        const allData = response.data.data
        setItems(allData);
        setTotalItems(allData.length)
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const subset = allData.slice(startIndex, endIndex);
        setItems(subset);
        setIsLoaded(true);
      } catch (error) {

      }
    }
    fetchOrder()
  }, [currentPage])


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);


  return (
    <div className='main-page bg4'>
      <DashboardHeader />
      <DashboardSidebar />

      <section className='main-section'>
        <div className='main-inner'>
          <DashboardTitle />

          <div className='white_box account-blocks'>
            <Row className='box-title v-center'>
              <Col xs={12} md={6} lg={6} className='left-block'>
                <h4>All Invoices</h4>
              </Col>
            </Row>

            <div className='account-table-block'>
              <Table className='w-100' bsPrefix borderless hover>
                <thead>
                  <tr>
                    <th width='200'>Product Name</th>
                    <th width='110'>Order ID</th>
                    <th width='136'>Date</th>
                    <th width='60'>Quantity</th>
                    <th width='80'>Price</th>
                    <th width='100'>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {items?.length > 0 ? items?.map((data, idx) => (
                    <tr key={idx}>
                      <td>{data.name}</td>
                      <td>#{data.id}</td>
                      <td>{data.order_date}</td>
                      <td>{data.quantity}</td>
                      <td>₹ {data.total_amount.toFixed(2)}</td>
                      <td><span className='btn small-btn completed'>Completed</span></td>
                    </tr>
                  )
                  ) :
                    <tr>
                      <td colSpan="6" className="text-lg-center">
                        No Data Found.
                      </td>
                    </tr>
                  }
                </tbody>
              </Table>
            </div>
            {
              totalItems ?
                <div className='pagination-block ptb-20 d-flex v-center'>
                  <div className='result-text'>
                    <p>{`Showing ${currentPage * itemsPerPage - itemsPerPage + 1} - ${Math.min(
                      currentPage * itemsPerPage,
                      totalItems
                    )} items out of ${totalItems} results found`}</p>
                  </div>
                  <Pagination className='ml-auto'>
                    <Pagination.First onClick={() => handlePageChange(1)} content='<i className="las la-angle-double-left"></i>' />
                    <Pagination.Prev onClick={() => handlePageChange(Math.max(currentPage - 1, 1))} />
                    {Array.from({ length: totalPages }, (_, index) => (
                      <Pagination.Item
                        key={index + 1}
                        active={index + 1 === currentPage}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    ))}
                 
                    <Pagination.Next
                      onClick={() =>
                        handlePageChange(Math.min(currentPage + 1, totalPages))
                      }
                    />
                    <Pagination.Last
                      onClick={() => handlePageChange(totalPages)}
                    />
                  </Pagination>
                </div>
                : ""
            }
          </div>
        </div>
      </section>
    </div>
  )
}
export default MyAccount