import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/site-logo-yellow.png';
import login_img from '../../assets/images/login-img.png';
import { login, google_login, facebook_login } from "../../actions/auth";
import { Link } from 'react-router-dom';
import Form from "react-validation/build/form";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { gapi } from "gapi-script";
import { Formik, Field, ErrorMessage } from 'formik';
import { loginSchema } from "./commonValidation"



var SCOPES = "https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/gmail.send";
const apiKey = process.env.GOOGLE_API;

function Login() {
    const [passwordType, setPasswordType] = useState("password");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const form = useRef();

    const { message } = useSelector(state => state.message);

    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: process.env.GOOGLE_API,
                scope: SCOPES,
            });
        }

        gapi.load('client:auth2', start);
    }, []);

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return true;
        }
        setPasswordType("password");
        return true;
    };

    const handleLogin = (values, { setSubmitting }) => {

        setLoading(true);
        dispatch(login(values.mobile_no, values.password))

            .then((response) => {
                navigate('/dashboard');
                window.location.reload();
            })
            .catch((err) => {
                setSubmitting(false);
                setLoading(false);
            });
    };

    return (
        <div>
            <section className='login-section'>
                <div className='circle-top-left'></div>
                <div className='circle-bottom-left'></div>
                <div className='pattern-top-center'></div>
                <div className='pattern-top-right'></div>
                <div className='pattern-bottom-right'></div>
                <div className='pattern-bottom-right'></div>
                <Container className='relative'>
                    <Row className='login-inner v-center ptb-100'>

                        <Col xs={12} md={12} lg={6} className='left-block'>
                            <div className='login-img-wrapper'>
                                <div className='logo-block text-center ptb-30 pt-0'>
                                    <img src={logo} alt='' />
                                </div>
                                <div className='login-imgbox'>
                                    <img src={login_img} alt='' />
                                </div>
                                <div className='glow-box'></div>
                            </div>
                        </Col>

                        <Col xs={12} md={12} lg={6} className='right-block d-flex'>
                            <div className='form-wrapper ml-auto mr-auto'>
                                <div className='logo-block text-center ptb-30 hide show_mobi_view'>
                                    <img src={logo} alt='' />
                                </div>
                                <h2 className='tl'>Log in to your Account</h2>
                                <Formik
                                    initialValues={{
                                        mobile_no: '',
                                        password: '',
                                    }}
                                    validationSchema={loginSchema}
                                    onSubmit={handleLogin}
                                >
                                    {({ handleSubmit, handleChange, values, isSubmitting }) => (
                                        <Form onSubmit={handleSubmit} ref={form}>
                                            <div className="input-field full-width">
                                                <label className="label" htmlFor="email">Mobile No. <em className='required'>*</em></label>
                                                <Field
                                                    type="text"
                                                    inputMode="numeric"
                                                    className="form-control email"
                                                    name="mobile_no"
                                                    placeholder="9999955555"
                                                    value={values.mobile_no}
                                                    onChange={handleChange}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/\D/g, '');
                                                        e.target.value = e.target.value.slice(0, 10);
                                                    }}
                                                />
                                                <ErrorMessage name="mobile_no" component="div" className="alert alert-danger mt-2" />
                                            </div>




                                            <div className="input-field full-width">
                                                <label className="label" htmlFor="password">Password <em className='required'>*</em></label>
                                                <div className="input-icon-block relative">
                                                    <Field
                                                        type={passwordType}
                                                        className="form-control"
                                                        name="password"
                                                        value={values.password}
                                                        placeholder="**********"
                                                        onChange={handleChange}
                                                        onInput={(e) => { e.target.value = e.target.value.slice(0, 20);}}
                                                    />
                                                    <ErrorMessage essage name="password" component="div" className="alert alert-danger mt-2" />
                                                    <div className="ped-inn" onClick={togglePassword}>
                                                        {passwordType === "password" ? (
                                                            <AiOutlineEye />
                                                        ) : (
                                                            <AiOutlineEyeInvisible />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="input-field full-width forget-pwd">
                                                <Link className="forgot-link" to="/forgot-password">Forgot Password?</Link>
                                            </div>

                                            <div className="input-field full-width text-center">
                                                <button className="btn btn secondary w-100" disabled={loading} type='submit'>
                                                    {loading && (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    )}
                                                    <span>Login</span>
                                                </button>
                                            </div>

                                            {message && (
                                                <div className="input-field full-width">
                                                    <div className="alert alert-danger" role="alert">
                                                    Incorrect mobile  number or password.
                                                    </div>
                                                </div>
                                            )}

                                        </Form>
                                    )}
                                </Formik>

                                <div className='link-box'>
                                    <p className='ml-2'>Doesn't have an account yet? <Link to='/register'>Create account</Link></p>
                                </div>

                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Login