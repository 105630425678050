import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import { Navbar, Container, Offcanvas} from 'react-bootstrap';
import logo from '../../assets/images/site-logo.png';


function Header(props) {
    return (
        <>
            <header className='header'>
                <Container>
                    <div className='header-inner d-flex v-center'>
                        <div className='site-logo'>
                            <Navbar.Brand>
                                <Link to='/'><img src={logo} alt='' /></Link>
                            </Navbar.Brand>
                        </div>

                        {['xl'].map((expand) => (
                            <Navbar key={expand} expand={expand}  >

                                <Navbar.Offcanvas className='mobi-nav-block w-100'
                                    id={`offcanvasNavbar-expand-${expand}`}
                                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                    placement='start'
                                    >
                                    <Offcanvas.Header closeButton className='mobile-header'>
                                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                            <div className='site-logo'>
                                                <Navbar.Brand>
                                                    <Link to='/home'>
                                                        <img src={logo} alt='' />
                                                    </Link>
                                                </Navbar.Brand>
                                            </div>
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>

                                    <Offcanvas.Body>
                                        <div className='head-center m-auto'>
                                            <nav className='menu_head'>
                                                <ul className='navbar-nav d-flex drop-hover'>
                                                    <li><Link className='nav-link' to='/dashboard'>Home</Link></li>
                                                    <li><Link className='nav-link' to='/about'>About Us</Link></li>
                                                    <li><Link className='nav-link' to='/'>Book an Appointment</Link></li>
                                                    <li><Link className='nav-link' to='/'>Book an Medicine</Link></li>
                                                    <li><Link className='nav-link' to='/'>EMI</Link></li>
                                                </ul>
                                            </nav>
                                        </div>

                                        <div className='head-right d-flex v-center'>
                                            <div className='head-btnbox'>
                                                <Link className='btn primary small' to='/login'><i className="las la-user mr-1"></i> Login</Link>
                                                <Link className='btn secondary small' to='/register'><i className="las la-lock mr-1"></i> Register</Link>
                                            </div>
                                        </div>
                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>
                                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                            </Navbar>
                        ))}
                    </div>
                </Container>
            </header>
            <Outlet />
        </>
    )
}

export default Header
