import React, { useState, useEffect } from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import { Link } from 'react-router-dom';
import { Row, Col} from 'react-bootstrap'
import {  useNavigate } from 'react-router-dom';
import useAxiosInstance from '../../Api/AxiosInstance';


function Provider() {
  const [items, setItems] = useState([]);
  let navigate = useNavigate();
  const AxiosInstance = useAxiosInstance()
  function redirectToDateTimeWithId(args) {
    localStorage.setItem("clinic_id", args);
    navigate("/date-time");
    window.location.reload();
  }

  useEffect(() => {
    const fetchClinics = async () =>{
      try {
        const response = await AxiosInstance.post("clinics")
        setItems(response.data.data);
      } catch (error) {
        
      }
    }
    fetchClinics()
  }, [])

  return (
    <div className='main-page bg4'>
      <DashboardHeader />
      <DashboardSidebar />

      <section className='main-section'>
        <div className='main-inner'>

          <DashboardTitle />

          <div className='provider-page-main'>

            <div className='box-title d-flex v-center'>
              <h3 className='mb-0'>Select a Location</h3>

              <div className='breadcrumb-block ml-auto'>
                <ul className='breadcrumb-list d-flex v-center'>
                  <li><Link to='/dashboard'>Home</Link></li>
                  <li>Location</li>
                </ul>
              </div>
            </div>

            <div className='provider-inn'>
              <Row className='provide-list'>
                {items?.map((data, idx) => (
                  <Col xs={12} md={12} lg={6} key={idx} className='items'>
                    <div className='white_box card-provider d-flex trans'>
                      <div className='number-circle d-flex v-center j-center'></div>
                      <div className='check-circle trans d-flex v-center j-center'></div>
                      <div className='textbox'>
                        <h4>{data.name}</h4>
                        <p>{data.address}, {data.city_name}, {data.state_name}, {data.country_name} - {data.pincode}</p>
                      </div>
                      <Link className='card-link' onClick={() => redirectToDateTimeWithId(data.id)}></Link>
                    </div>
                  </Col>
                )
                )}
              </Row>
            </div>

          </div>
        </div>
      </section>
    </div>
  )
}
export default Provider