import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap'
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import UserInformation from './dashboard_modules/UserInformation';
import ChatBlock from './dashboard_modules/ChatBlock';
import ChatBlockMobile from './dashboard_modules/ChatBlockMobile';

function MyAccount() {

  // Media queryusing hooks
  const [isDesktop, setDesktop] = useState(window.innerWidth > 991);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 991);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  // End

  return (
    <>
    { isDesktop ? (
    <>
    <div className='main-page bg4'>
      <DashboardHeader />
      <DashboardSidebar />

      <section className='main-section'>
        <div className='main-inner'>

          <DashboardTitle />

          <Row className='dashboard-blocks'>

            <Col xs={12} md={6} lg={6} className='left-block'>
              <UserInformation />
            </Col>

            <Col xs={12} md={6} lg={6} className='right-block'>
              <ChatBlock />
            </Col>

          </Row>
        </div>
      </section>
    </div>
    </>
    ) : (
    <div className='main-page bg4'>
      <DashboardHeader />
      <DashboardSidebar />

      <section className='main-section'>
        <div className='main-inner'>

          <DashboardTitle />

          <Row className='dashboard-blocks'>

            <Col xs={12} md={12} lg={6} className='left-block'>
              <UserInformation />
            </Col>

            <Col xs={12} md={12} lg={6} className='right-block mt-3'>
              {/* <ChatBlockMobile /> */}
              <ChatBlock />
            </Col>

          </Row>
        </div>
      </section>
    </div>
    )}
    </>
  )
}
export default MyAccount