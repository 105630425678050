import React, { useState, useEffect } from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import { Link } from 'react-router-dom';
import { Row, Col, Table } from 'react-bootstrap'
import configData from "../../config.json";
import Pagination from 'react-bootstrap/Pagination';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import useAxiosInstance from '../../Api/AxiosInstance';

function MyAccount() {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(configData.DATA_PER_PAGE);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoaded, setIsLoaded] = useState("Loading...");
  const AxiosInstance = useAxiosInstance()



  useEffect(() => {
    const fetchPrescription = async () => {
      try {
        const response = await AxiosInstance.post('my-prescriptions')
        const allData = response.data.data
        const sortedData = allData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setItems(sortedData);
        setTotalItems(sortedData.length)

        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const subset = sortedData.slice(startIndex, endIndex);

        setItems(subset);
        setIsLoaded(true);
      } catch (error) {

      }
    }
    fetchPrescription()
  }, [currentPage])

  return (
    <div className='main-page bg4'>
      <DashboardHeader />
      <DashboardSidebar />

      <section className='main-section'>
        <div className='main-inner'>

          <DashboardTitle />

          <div className='white_box account-blocks'>
            <Row className='box-title v-center'>
              <Col xs={12} md={6} lg={6} className='left-block'>
                <h4>All Prescription</h4>
              </Col>
            </Row>

            <div className='account-table-block'>
              <Table className='w-100' bsPrefix borderless hover>
                <thead>
                  <tr>
                    <th width='200'>Prescription Date</th>
                    <th width='136'>Download Prescription</th>
                  </tr>
                </thead>
                <tbody>
                  {items?.length > 0 ? items?.map((data, idx) => (
                    <tr key={idx}>
                      <td>{data.visited_at}</td>
                      <td><Link className='btn small-btn red-btn' to={data.file_url} target='_blank'><i className="download_icon"></i> Download</Link></td>
                    </tr>
                  )) :
                    <tr>
                      <td colSpan="6" className="text-lg-center">
                        No Data Found.
                      </td>
                    </tr>}

                </tbody>
              </Table>
            </div>

            {
              totalItems > 10 ?
                <div className='pagination-block ptb-20 d-flex v-center'>
                  <div className='result-text'>
                    <p>{`Showing ${currentPage * itemsPerPage - itemsPerPage + 1} - ${Math.min(
                      currentPage * itemsPerPage,
                      totalItems
                    )} items out of ${totalItems} results found`}</p>
                  </div>
                  <Pagination className='ml-auto'>
                    <PaginationControl
                      page={currentPage}
                      between={4}
                      total={totalItems}
                      limit={10}
                      last={true}
                      changePage={(page) => {
                        setCurrentPage(page)
                      }}
                      ellipsis={1}
                    />
                  </Pagination>
                </div>
                : ""
            }
          </div>

        </div>
      </section>
    </div>
  )
}
export default MyAccount