import React, { useState, useEffect } from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import { Link } from 'react-router-dom';
import { Row, Col} from 'react-bootstrap'
import useAxiosInstance from '../../Api/AxiosInstance';



function Review() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const AxiosInstance = useAxiosInstance()

    useEffect(() => {
        const fetchClinic = async () =>{
            try {
                const response = await AxiosInstance.post('clinics')
                setIsLoaded(true);
                setItems(response.data.data);
            } catch (error) {
                
            }
        }
    fetchClinic()
    }, [])

    return (
        <div className='main-page bg4'>
            <DashboardHeader />
            <DashboardSidebar />

            <section className='main-section'>
                <div className='main-inner'>
                    <DashboardTitle />
                    <div className='provider-page-main'>
                        <div className='box-title d-flex v-center'>
                            <h3 className='mb-0'>Select a Provider</h3>
                            <div className='breadcrumb-block ml-auto'>
                                <ul className='breadcrumb-list d-flex v-center'>
                                    <li><Link to='/dashboard'>Home</Link></li>
                                    <li>Location</li>
                                </ul>
                            </div>
                        </div>

                        <div className='provider-inn'>
                            <Row className='provide-list'>
                                {items?.map((data, idx) => (
                                    <Col xs={12} md={12} lg={6} key={idx} className='items'>
                                        <div className='white_box  d-flex trans'>
                                            <div className='textbox'>
                                                <Link target='_blank' to={data.google_review_link}>{data.name} Branch Google Review</Link>
                                            </div>
                                        </div>
                                    </Col>
                                )
                                )}
                            </Row>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Review