import React, { useState, useEffect, useCallback } from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Modal, Form } from 'react-bootstrap'
import razorpay_icon from '../../assets/images/razorpay_icon.svg';
import Input from "react-validation/build/input";
import useRazorpay from "react-razorpay";
import { ToastContainer, toast } from 'react-toastify'
import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";
import { useStore } from "react-redux";
import Accordion from 'react-bootstrap/Accordion';
import { Formik, Field, ErrorMessage } from 'formik';
import { ShippingAddress } from './commonValidation';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import useAxiosInstance from '../../Api/AxiosInstance';


function Checkout() {
  const dispatch = useDispatch();
  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);
  const { getState } = useStore((state) => state);
  const { user } = getState().auth
  let navigate = useNavigate();
  let [orderId, setOrderId] = useState('');
  const [addres_id, setAddress_id] = useState('')
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => {
    setShow3(false);
  };
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showEditForm, setShowEditForm] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [items, setItems] = useState([]);
  const [address, setAddress] = useState([])
  const [razorpay, isLoaded] = useRazorpay();
  const [geoaddress, setGeoaddress] = useState('')
  const [error, setError] = useState('');
  const [locationError, setLocationError] = useState(false)
  const [show, setShow] = useState(false)
  const handleClose = () => { setShow(false); setSelectedAddress(null) }
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null
  })
  let [shipping, setShipping] = useState(15)
  let [vat, setVat] = useState(0)
  const AxiosInstance = useAxiosInstance()

  let sum = 0
  for (let i = 0; i < items.length; i++) {
    sum += items[i].sub_total
  }

  let total = sum + shipping + vat

  useEffect(() => {
    const fetchaddress = async () => {
      try {
        const response = await AxiosInstance.post('billing-address')
        setAddress(response.data.data)
      } catch (error) {

      }
    }
    fetchaddress()
  }, [show, isFormVisible])

  const handleEditAddress = (address) => {
    setCoordinates({ lat: address.lat, lng: address.lng })
    setSelectedAddress(address);
    setShowEditForm(!showEditForm);
  };

  const handleSelect = async (value, setFieldValue) => {
    try {
      const result = await geocodeByAddress(value);
      const ll = await getLatLng(result[0]);
      if (ll.lat == '' || ll.lng == '') {
        setLocationError(true)
      }
      setFieldValue('location', value); // Update Formik field value
      setFieldValue('lat', ll.lat)
      setFieldValue('lng', ll.lng)
      setGeoaddress(value);
      setCoordinates(ll);
    } catch (error) {
      console.error('Error selecting address:', error);
      setError('Error fetching location details');
    }
  };

  let User_id = user?.user?.id
  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const response = await AxiosInstance.post('cart-lists')
        setItems(response.data.data);
      } catch (error) {

      }
    }
    fetchAddress()
  }, [showEditForm, isFormVisible])

  let cartIds = [];

  for (let i = 0; i < items.length; i++) {
    var c_id = items[i].cart_id;
    cartIds.push(c_id);
  }
  let cart_id = cartIds.join(',');

  const handlePayment = async (amount) => {
    if (addres_id == '') {
      toast.error('Please select address.', {
        position: "top-right",
        autoClose: 2000,
      });
    } else {
      try {
        const orderDetailsResponse = await AxiosInstance.post('add-order-details', { total_amount: amount, sub_total: sum, user_id: User_id, cart_id: cart_id, address_id: addres_id });
        const checkedorderId = orderDetailsResponse.data.data.id;

        const response = await AxiosInstance.post("create-order-id-in-razorpay", { amount: amount });
        if (response.status === 400 && response.message === "Token is expired!!") {
          logOut();
          navigate("/login");
        } else {
          if (response.status === 200) {
            setOrderId(response.data.data.order_id);
            setShow3(true);
            const razorpay_key = process.env.RAZORPAY_KEY_ID;

            const options = {
              key: 'rzp_test_3PYKIWLbUWhl67',
              accept_partial: false,
              amount: Math.round(amount * 100),
              currency: "INR",
              name: "Eugenix",
              description: "Test Transaction",
              image: "http://localhost/euginix_crm_backend/assets/front/images/logo.png",
              order_id: orderId,
              handler: async (res) => {
                // setPayment_id(res.razorpay_payment_id);
                try {
                  if (res.razorpay_payment_id) {
                    const response = await AxiosInstance.post("add-payment-details", { table_id: checkedorderId, type_id: 2, payment_id: res.razorpay_payment_id, amount: amount })
                    if (response.data.status == 200) {
                      navigate('/myorder')
                      window.location.reload()
                    }
                  }
                } catch (error) {
                  console.log(error)
                }
              },
              notes: {
                permanent_address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#3399cc",
              },
              modal: {
                ondismiss: function () {
                  window.location.reload()
                }
              }
            };
            const rzpay = new razorpay(options);
            rzpay.open();
          }
        }
      } catch (error) {
        console.log(error)
        // Handle errors here
      }
    }
  }

  const handleUpdate = async (values) => {
    if (coordinates.lat == null || coordinates.lng == null) {
      setLocationError(true)
    } else {
      try {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
          acc[key] = typeof values[key] === 'string' ? values[key].trim() : values[key];
          return acc;
        }, {});

        const response = await AxiosInstance.post('add-user-address', trimmedValues);
        if (response.data.success == 1) {
          setShow(false)
          toast.success('Address updated Successfully', {
            position: "top-right",
            autoClose: 1000,
          });
          setShowEditForm(false);
          setLocationError(false)
          setSelectedAddress(null)
        }
      } catch (error) {
        console.error("Error in handleUpdate:", error);
      }
    }
  };


  return (
    <div className='main-page bg4'>
      <DashboardHeader />
      <DashboardSidebar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <section className='main-section'>
        <div className='main-inner'>
          <DashboardTitle />
          <div className='checkout-page-main'>
            <div className='breadcrumb-block ptb-20 pt-0'>
              <ul className='breadcrumb-list d-flex v-center'>
                <li><Link to='/dashboard'>Home</Link></li>
                <li><Link to='/shop/'>Order medicine</Link></li>
                <li><Link to='/cart/'>Cart</Link></li>
                <li>Checkout</li>
              </ul>
            </div>

            <div className='checkout-form'>
              <Row className='checkout-blocks'>
                <Col xs={12} md={12} lg={8} className='left-block'>
                  <div className='white_box checkout-wrapper'>
                    <div className='box-title'>
                      <h3>Select Billing address</h3>
                    </div>
                    <div className='form-inner d-flex'>
                      {address?.map((u, index) =>
                        <Form.Group className='input-field full-width relative' key={u.id}>
                          <label className='block-radiobox'>
                            <Form.Control type='radio' name="address" value={u.id} onChange={() => setAddress_id(u.id)} />
                            <div className='card-address trans' key={index} >
                              <h4>{u.full_name}</h4>
                              <p>{u.current_address} , {u.permanent_address} , {u.location} , {u.pincode}  </p>
                            </div>
                          </label>
                          <button onClick={() => { handleEditAddress(u); setShow(true); setLocationError(false) }} className='edit-add-btn'>
                            <i className='fa fa-pencil'></i>
                          </button>
                        </Form.Group>
                      )}
                    </div>
                    <div className='address-new-block ptb-30 pb-0'>
                      <span className='btn gray-btn' onClick={() => { setShow(true) ; setLocationError(false) }}>+ Add New Address</span>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={12} lg={4} className='right-block'>
                  <div className='white_box cart-list-wrapper checkout-cart-list'>
                    {
                      items?.map((item, index) =>
                        <div className='cart-items d-flex v-center' key={index}>
                          <div className='cart-imgbox d-flex'>
                            <img src={item.image} alt='' />
                          </div>
                          <div className='cart-info-textbox'>
                            <div className='sub-item d-flex'>
                              <label className="cart-label">Product Name :</label>
                              <span className='cart-value'>{item.name}</span>
                            </div>
                            <div className='sub-item d-flex'>
                              <label className="cart-label">Price :</label>
                              <span className='cart-value'>₹ {item.sub_total.toFixed(2)}</span>
                            </div>
                            <div className='sub-item d-flex'>
                              <label className="cart-label">Quantity :</label>
                              <span className='cart-value'> {item.quantity} </span>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className='white_box cart-total-wrapper'>
                    <div className='box-title text-center'>
                      <h4>Cart Total</h4>
                    </div>
                    <div className='cart-total-list'>
                      <div className='sub-item d-flex'>
                        <label className="cart-label">Subtotal</label>
                        <span className='cart-value'>₹ {sum.toFixed(2)}</span>
                      </div>
                      <div className='sub-item d-flex'>
                        <label className="cart-label">Shipping and Handing</label>
                        <span className='cart-value'>₹ {shipping.toFixed(2)}</span>
                      </div>
                      <div className='sub-item d-flex'>
                        <label className="cart-label">Vat</label>
                        <span className='cart-value'>₹ {vat.toFixed(2)}</span>
                      </div>
                      <div className='sub-item d-flex grand-total-item'>
                        <label className="cart-label"><strong>Total</strong></label>
                        <span className='cart-value'><strong>₹ {total.toFixed(2)}</strong></span>
                      </div>
                      <div className='sub-item d-flex form-btnbox'>
                        <Link className='btn secondary w-100' onClick={() => handlePayment(total)}  >Proceed to checkout <i className="las la-shopping-cart ml-2"></i></Link>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose} size="md" centered>
        <div className='checkout-form'>
          <Row className='checkout-blocks'>
            <Col xs={12} md={12} lg={12} >
              <div className='white_box checkout-wrapper'>
                <h3 className='text-center'>Address Details</h3>
                <Formik
                  initialValues={{
                    id: selectedAddress ? selectedAddress.id : '',
                    full_name: selectedAddress ? selectedAddress.full_name : '',
                    mobile_no: selectedAddress ? selectedAddress.mobile_no : '',
                    gst_no: selectedAddress ? selectedAddress.gst_no == "null" ? "" : selectedAddress.gst_no : '',
                    permanent_address: selectedAddress ? selectedAddress.permanent_address : '',
                    current_address: selectedAddress ? selectedAddress.current_address : '',
                    pincode: selectedAddress ? selectedAddress.pincode : '',
                    location: selectedAddress ? selectedAddress.location : '',
                    lat: selectedAddress ? selectedAddress.lat : '',
                    lng: selectedAddress ? selectedAddress.lng : '',
                  }}
                  validationSchema={ShippingAddress}
                  onSubmit={handleUpdate}
                >
                  {({ handleSubmit, values, handleChange, setFieldValue }) => (
                    <Form className='mt-4' onSubmit={handleSubmit}>
                      <div className='form-inner d-flex'>
                        <div className='input-field full-width'>
                          <label className='label'>Full Name <em className='required'>*</em></label>
                          <Field type='text'
                            name="full_name"
                            placeholder="Enter Full Name"
                            value={values.full_name}
                            onChange={handleChange} />
                          <ErrorMessage name="full_name" >
                            {msg => (
                              <div className="alert alert-danger mt-3" role="alert">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className='input-field full-width'>
                          <label className='label'>Mobile no. <em className='required'>*</em></label>
                          <input type='text'
                            inputMode='numeric'
                            name="mobile_no"
                            placeholder="Enter Mobile No."
                            value={values.mobile_no}
                            onChange={handleChange}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/\D/g, '');
                              e.target.value = e.target.value.slice(0, 10);
                            }} />
                          <ErrorMessage name="mobile_no" >
                            {msg => (
                              <div className="alert alert-danger mt-3" role="alert">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className='input-field full-width'>
                          <label className='label'>GSTIN Number (optional)</label>
                          <input type='text' name="gst_no"
                            placeholder='Enter GSTIN Number'
                            value={values.gst_no}
                            onChange={handleChange}
                            maxLength={15}
                          />
                          <ErrorMessage name="gst_no" >
                            {msg => (
                              <div className="alert alert-danger mt-3" role="alert">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className='input-field full-width'>
                          <label className='label'>Street Address <em className='required'>*</em></label>
                          <input type='text'
                            name="permanent_address"
                            placeholder='Enter Address'
                            value={values.permanent_address}
                            onChange={handleChange} />
                          <ErrorMessage name="permanent_address" >
                            {msg => (
                              <div className="alert alert-danger mt-3" role="alert">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className='input-field full-width'>
                          <label className='label'>Current Address <em className='required'>*</em></label>
                          <input type='text'
                            name="current_address"
                            placeholder='Enter Address'
                            value={values.current_address}
                            onChange={handleChange} />
                          <ErrorMessage name="current_address" >
                            {msg => (
                              <div className="alert alert-danger mt-3" role="alert">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className='input-field full-width'>
                          <label className='label'>Location <em className='required'>*</em></label>
                          <PlacesAutocomplete
                            value={values.location}
                            onChange={(address) => { setFieldValue('location', address); setFieldValue('lat', ''); setFieldValue('lng', ''); setCoordinates({ lat: null, lng: null }); setLocationError(false) }}
                            onSelect={(address) => handleSelect(address, setFieldValue)}
                          >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <div key={suggestions.description}>
                                <input
                                  {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input',
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions?.map(suggestion => {
                                    return (
                                      <ul
                                        {...getSuggestionItemProps(suggestion, {
                                        })}
                                      >
                                        <li>{suggestion.description}</li>
                                      </ul>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          {locationError ?
                            <div className="alert alert-danger mt-3" role="alert">Please select valid address</div>
                            : ""}
                          <ErrorMessage name="location" >
                            {msg => (
                              <div className="alert alert-danger mt-3" role="alert">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className='input-field full-width'>
                          <label className='label'>Pin Code <em className='required'>*</em></label>
                          <input type='text'
                            name="pincode"
                            placeholder='Enter Pin Code'
                            value={values.pincode}
                            onChange={handleChange}
                            maxLength={6} />
                          <ErrorMessage name="pincode" >
                            {msg => (
                              <div className="alert alert-danger mt-3" role="alert">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className='input-field full-width form-btnbox  mb-0 pt-2'>
                          <button className='btn secondary ml-auto' variant="primary" type="submit" >Submit</button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}
export default Checkout