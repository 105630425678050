import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';

function Footer() {

  return (
    <>
    <footer className='footer'>
        <Container>
            <div className='d-flex v-center footer-inner-block ptb-40'>
                <div className='items f_col_1 mr-auto'>
                    <div className='copyright-textbox'>
                        <p className='mb-0 text-white'>© 2023, Eugenix Hairsciences Pvt. Ltd. - All Right Reserved</p>
                    </div>
                </div>

                <div className='items f_col_2 ml-auto mr-auto'>
                    <ul className='f-links d-flex'>
                        <li><Link to='#'>Terms & Conditions</Link></li>
                        <li><Link to='#'>Privacy Policy</Link></li>
                    </ul>
                </div>
               
                <div className='items f_col_3 ml-auto'>    
                    <div className='social-link d-flex v-center'>
                        <Link to='#' target='_blank'><i className="fa fa-instagram"></i></Link>
                        <Link to='#' target='_blank'><i className="fa fa-youtube"></i></Link>
                        <Link to='#' target='_blank'><i className="fa fa-twitter"></i></Link>
                        <Link to='#' target='_blank'><i className="fa fa-linkedin"></i></Link>
                        <Link to='#' target='_blank'><i className="fa fa-facebook"></i></Link>
                        <Link to='#' target='_blank'><i className="fa fa-pinterest"></i></Link>
                    </div>
                </div>
            </div>
        </Container>
    </footer>
    </>
  )
}

export default Footer