import * as Yup from 'yup';
import configData from "../../config.json"


const strongPassword = Yup.string()
    .required(configData.PASSWORD)
    .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'At least 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character required'
    );

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const registerScema = Yup.object().shape({
    full_name: Yup.string().matches(/^[A-Za-z ]+$/, configData.FULL_NAME_LATTER).required(configData.FULL_NAME).trim(),
    email_id: Yup.string().email(configData.INVAlID_EMAIL).required(configData.EMAIL_ID),
    mobile_no: Yup.string().required(configData.MOBILE_NO)
        .matches(phoneRegExp, configData.MOBILE_LENGTH).min(10, configData.MOBILE_LENGTH).max(10, configData.MOBILE_LENGTH),
    gender: Yup.string().required(configData.GENDER).oneOf(['male', 'female'], 'Invalid Gender'),
    password: strongPassword,
    confirm_password: Yup.string()
        .required(configData.CONFIRM_PASSWORD)
        .oneOf([Yup.ref('password'), null], configData.PASSWORD_MATCH),
    termsandConditions: Yup.boolean().oneOf([true], configData['TERM_&_CONDITION']),

});




export const loginSchema = Yup.object().shape({
    mobile_no: Yup.string().required(configData.MOBILE_NO)
        .matches(phoneRegExp, configData.MOBILE_LENGTH).min(10, configData.MOBILE_LENGTH).max(10, configData.MOBILE_LENGTH),
    password: Yup.string().required(configData.PASSWORD),
})


export const ForgotPasswordSchema = Yup.object().shape({
    mobile_no: Yup.string().required(configData.MOBILE_NO)
        .matches(phoneRegExp, configData.MOBILE_LENGTH).min(10, configData.MOBILE_LENGTH).max(10, configData.MOBILE_LENGTH),
    email: Yup.string().email(configData.INVAlID_EMAIL).required(configData.EMAIL_ID),

})


export const changePassword = Yup.object().shape({
    current_password: Yup.string().required(configData.CURRENT_PASSWORD),
    password: strongPassword,
    confirm_password: Yup.string()
        .required(configData.CONFIRM_PASSWORD)
        .oneOf([Yup.ref('password'), null], configData.PASSWORD_MATCH),
})


export const ShippingAddress = Yup.object().shape({
    permanent_address: Yup.string().trim().required(configData.ADDRESS),
    mobile_no: Yup.string().required(configData.MOBILE_NO)
        .matches(phoneRegExp, configData.MOBILE_LENGTH).min(10, configData.MOBILE_LENGTH).max(10, configData.MOBILE_LENGTH),
    pincode: Yup.string().trim().required(configData.PIN_CODE).matches(/^[\w\s]{6}$/, configData.PIN_CODE_LENGTH),
    full_name: Yup.string().matches(/^[A-Za-z ]+$/, configData.FULL_NAME_LATTER).required(configData.FULL_NAME).trim(),
    current_address: Yup.string().trim().required(configData.CURRENT_ADDRESS),
    location: Yup.string().trim().required(configData.LOCATION_ADDRESS)
})


export const EditProfileSchema = Yup.object().shape({
    full_name: Yup.string().matches(/^[A-Za-z ]+$/, configData.FULL_NAME_LATTER).required(configData.FULL_NAME).trim(),
    gender: Yup.string().required(configData.GENDER),
    // dob: Yup.string().required(configData.DOB),
    mobile_no: Yup.string().required(configData.MOBILE_NO)
        .matches(phoneRegExp, configData.MOBILE_LENGTH).min(10, configData.MOBILE_LENGTH).max(10, configData.MOBILE_LENGTH),
})