import React, { useState, useEffect } from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col, Form } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import useAxiosInstance from '../../Api/AxiosInstance';

function MyAccount() {
  const [items, setItems] = useState([]);
  const [fileErrors, setFileErrors] = useState('');
  const [selectedImage, setSelectedImage] = useState([]);
  const AxiosInstance = useAxiosInstance();
  const [dob, setDob] = useState('');
  const [uploadImage, setUploadImage] = useState([]);
  const [labels, setLabels] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [formsubmit, setFormsubmit] = useState(false)

  const user = JSON.parse(localStorage.getItem('user'))

  function handleChange1(e) {
    const files = e.target.files;
    const newFiles = Array.from(files);
    const fileTypes = Array.from(files)?.map(file => file.type);
    const validTypes = fileTypes.every(type => type === "image/jpeg" || type === "image/png" || type === "image/gif");

    if (!validTypes) {
      toast.error("Only JPEG, PNG, and GIF files are allowed.");
      return;
    }

    const blobFiles = newFiles?.map(file => URL.createObjectURL(file));
    setSelectedImage(prevBlobs => [...prevBlobs, ...blobFiles]);
    setUploadImage(prevFiles => [...prevFiles, ...newFiles]);
  }

  const handleRemove = (index) => {
    setSelectedImage((prevBlobs) => prevBlobs.filter((_, i) => i !== index));
    setUploadImage((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await AxiosInstance.post('user/image/get');
        const { user_upload_images, labels, headers } = response.data.data;
        console.log(response.data.data)
        setItems(response.data.data.user_upload_images || []);
        setLabels(response.data.data.lables || []);
        setHeaders(response.data.data.headers || []);
      } catch (error) {
        // Handle error if necessary
      }
    };
    fetchImages();
  }, [formsubmit]);

  /*Function for upload images*/
  const uploadImages = async (formData) => {
    try {
      const response = await AxiosInstance.post('user/image/add', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      if (response.data.success == '1') {
        setFormsubmit(!formsubmit)
        toast.success('Image uploaded successfully.');
        setSelectedImage([])
        setUploadImage([])
        setDob('')
      }
    } catch (error) {
      // Handle error if necessary
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form submission

    if (uploadImage.length === 0) {
      toast.error('Please select an image.');
      return;
    }
    if (!dob) {
      toast.error('Please select a date.');
      return;
    }

    const formData = new FormData();
    uploadImage.forEach((image, index) => {
      formData.append(`images[${index}]`, image);
    });
    formData.append('date', dob);
    uploadImages(formData);
  };

  console.log('labels:', labels);
  console.log('headers:', headers);
  console.log('items:', items);

  return (
    <div className='main-page bg4'>
      <ToastContainer />
      <DashboardHeader />
      <DashboardSidebar />

      <section className='main-section'>
        <div className='main-inner'>
          <DashboardTitle />

          <div className='white_box account-blocks'>
            <div className='box-title'>
              <h3>Upload Photos</h3>
            </div>

            <div className='myphoto-block'>
              <Row>
                <Col xs={12} md={8} lg={12} className='items'>
                  <Form className='form-inner emi-form-main photo-gallary' onSubmit={handleSubmit}>
                    <div className='input-field full-width'>
                      <Row>
                        <Col xs={12} md={8} lg={4}>
                          <div className={`file-upload-box `}>
                            <label className='upload-img1 w-100'>
                              <div className='drag-drop-box d-flex'>
                                <i className="las la-image"></i><span>Drag & Drop or Choose file to upload</span>
                              </div>
                              <input type="file" name="adhar_card_file" onChange={handleChange1} accept="image/png, image/jpeg , image/gif" multiple="multiple" />
                            </label>
                          </div>
                        </Col>
                        <Col xs={12} md={8} lg={6}>
                          <div className='input-field'>
                            <label className='label'>Date</label>
                            <input
                              type='date'
                              name='dob'
                              value={dob}
                              onChange={(e) => setDob(e.target.value)}
                              min={user?.user?.created_at.split(' ')[0]} // Extracting YYYY-MM-DD from the datetime string
                              onKeyDown={(e) => e.preventDefault()} // Prevents input using keyboard (optional)
                            />
                          </div>
                        </Col>
                      </Row>

                      <div className='upload-imgs d-flex mt-2'>
                        {selectedImage && selectedImage.map((data, idx) => (
                          <div className='imgbox ' key={idx}>
                            <i onClick={() => handleRemove(idx)} className="fa fa-times" aria-hidden="true"></i>
                            <img src={data} alt="Uploaded Image" />
                          </div>
                        ))}
                      </div>

                      {labels.map((label, index) => (
                        <div key={index} className='mt-2'>
                          <h4>{headers[index]}</h4>
                          <div className='upload-imgs d-flex'>
                            {items
                              .filter(item => label === item.photo_lable)
                              .map((data, idx) => (
                                <div className='imgbox' key={idx}>
                                  <img src={data.file_url} alt="Image" />
                                </div>
                              ))}
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className='input-field full-width form-btnbox pt-2 mb-0'>
                      <button className='btn secondary mw_200' type='submit'>Submit</button>
                    </div>
                  </Form>
                </Col>
              </Row>

              <div className='ptb-40 pb-0'>
                <p>If you have any concerns, please <strong><Link to='tel:0123 456789'>call here</Link></strong></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MyAccount;