import React from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import { Link } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap'
import { useStore } from "react-redux";

function Dashboard() {
  const { getState } = useStore((state) => state);
  const { user } = getState().auth
  const image_url = { image: user?.user?.image_url ? user?.user?.image_url : user?.user?.image }
  const file = image_url.image

  return (
    <div className='main-page bg4'>
      <DashboardHeader />
      <DashboardSidebar />

      <section className='main-section'>
        <div className='main-inner'>

          <DashboardTitle />


          <Row className='dashboard-blocks'>

            <Col xs={12} md={8} lg={8} className='left-block'>
              <div className='white_box profile-form-block'>
                <div className='box-title'>
                  <h3>My Profile</h3>
                </div>

                <Form className='form-inner d-flex'>
                  <Form.Group className='input-field'>
                    <label className='label'>Full Name</label>
                    <Form.Control type='text' disabled value={user?.user?.full_name} />
                  </Form.Group>

                  <Form.Group className='input-field'>
                    <label className='label'>Mobile No.</label>
                    <Form.Control type='tel' disabled value={user?.user?.mobile_no} />
                  </Form.Group>

                  <Form.Group className='input-field'>
                    <label className='label'>Email ID</label>
                    <Form.Control type='email' disabled value={user?.user?.email_id} />
                  </Form.Group>

                  <div className='input-field'>
                    <label className='label'>Gender</label>
                    <div className="option-block d-flex v-center mt-1">
                      <label className="radiobox pe-none">
                        <input disabled type="radio" name="gender" value={1} checked={user?.user?.gender == '1'} />
                        <span className="checkmark "></span> Male
                      </label>
                      <label className="radiobox pe-none">
                        <input disabled type="radio" name="gender" value={2} checked={user?.user?.gender == '2'} />
                        <span className="checkmark"></span> Female
                      </label>
                    </div>
                  </div>

                  <Form.Group className='input-field full-width form-btnbox d-flex mb-0 pt-2'>
                    <Link className='btn gray-btn' to='/edit-profile'><i className="las la-pen ml-0 mr-2"></i> Edit Profile</Link>
                    {/* <Button className='btn secondary ml-auto' variant="primary" type="submit" disabled>Update</Button> */}
                  </Form.Group>

                </Form>

              </div>
            </Col>

            <Col xs={12} md={4} lg={4} className='right-block'>
              <div className='white_box prifile-about text-center'>
                <i className='imgbox'>
                  <img src={file} alt='' />
                </i>
                <div className='textbox'>
                  <h4>{user.user.full_name}</h4>
                  {user?.user?.bitrix_lead_id && <h4>  Bitrix ID: {user.user.bitrix_lead_id}</h4>}
                </div>
              </div>
            </Col>

          </Row>

        </div>
      </section>
    </div>
  )
}
export default Dashboard