import React, { useState, useCallback, useEffect } from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Modal } from 'react-bootstrap'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import { useDispatch } from "react-redux";
import product_img from '../../assets/images/dr.png';
import Moment from 'moment';
import { logout } from "../../actions/auth";
import useRazorpay from "react-razorpay";
import Cookies from 'js-cookie';
import useAxiosInstance from '../../Api/AxiosInstance';

function renderEventContent(eventInfo) {
  return (
    <>
      <div className='avail_app'>
        <img src={eventInfo.event.url} width="25px" /><br></br>
        <i>{eventInfo.event.title}</i>
      </div>
    </>
  )
}

const clinic_id = JSON.parse(localStorage.getItem("clinic_id"));
function DateTime() {
  const dispatch = useDispatch();
  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  let navigate = useNavigate();

  const displayData = [];
  const availabilityData = [];
  const [slots, setSlots] = useState('');
  const [events, setEvents] = useState([]);
  const [schedule_date, setDate] = useState('');
  const [schedule_from_time, setStartTime] = useState('');
  const [schedule_to_time, setEndTime] = useState('');
  const AxiosInstance = useAxiosInstance()


  const handleEventClick = async (clickInfo) => {
    try {
      setShow(true);
      const clinic_id = 1;
      const date = Moment(clickInfo.event._instance.range.start).format('YYYY-MM-DD');
      setDate(date);

      const response = await AxiosInstance.post('clinic/availability', { clinic_id: clinic_id, schedule_date: date })
      const obj = Object.entries(response.data.data.events)
      obj.forEach(([key, value]) =>
        displayData.push(<label onClick={() => { setScheduleTime(value.slot_start_time, value.slot_end_time); }} className='radiobox time-radio'><Form.Control type='radio' name="Time" value={value.slot_start_time} /><span className='checkmark'></span>{value.slot_start_time}</label>)
      )
      setSlots(displayData);
    } catch (error) {

    }
  }
  
  function setScheduleTime(start_time, end_time) {
    setStartTime(start_time);
    setEndTime(end_time);
    handleShow2();
  }

  const bookAnAppointment = async () => {
    try {
      const response = await AxiosInstance.post("appointment/add", { clinic_id, schedule_date, })
      if (response.data.status == 200) {
        navigate("/thankyou", { state: response.data.data.appointment },);
        window.location.reload();
      }
    } catch (error) {

    }
  }

  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false); setChargerror(false) };
  const [razorpay, isLoaded] = useRazorpay();
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  let [orderId, setOrderId] = useState('');
  const [appointment_id, setAppointment_id] = useState('')
  const [show3, setShow3] = useState(false);
  const [selectedCharge, setSelectedCharge] = useState(null); // State to store the selected charge
  const [chargerror, setChargerror] = useState(false);
  const [cahrges, setCharges] = useState([])
  const [options, setOptions] = useState(
    {
      key: 'rzp_test_3PYKIWLbUWhl67',
      accept_partial: false,
      amount: Math.round(selectedCharge * 100),
      currency: "INR",
      name: "Eugenix",
      description: "Test Transaction",
      image: "http://localhost/euginix_crm_backend/assets/front/images/logo.png",
      order_id: orderId,
      handler: async (res) => {
        if (res.razorpay_payment_id) {
          try {
            const checkedorderId = Cookies.get('checkedorderId');
            const response = await AxiosInstance.post('add-payment-details', { table_id: checkedorderId, type_id: 1, payment_id: res.razorpay_payment_id, amount: selectedCharge })
            if (response.data.status == 200) {
              navigate('/appointments')
              window.location.reload()
            }

          } catch (error) {

          }
        }
      },
      notes: {
        permanent_address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    }
  )
  const handleChargeChange = (charge) => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      amount: charge * 100,
      handler: async (res) => {
        if (res.razorpay_payment_id) {
          try {
            const checkedorderId = Cookies.get('checkedorderId');
            const response = await AxiosInstance.post('add-payment-details', { table_id: checkedorderId, type_id: 1, payment_id: res.razorpay_payment_id, amount: charge })
            if (response.data.status == 200) {
              navigate('/appointments')
              window.location.reload()
            }

          } catch (error) {

          }
        }
      },
    }));
    setSelectedCharge(charge);
    setChargerror(false); // Reset error message when a charge is selected
  };



  const handleShow3 = async () => {
    if (!selectedCharge) {
      setChargerror(true)
    }
    else {
      setShow(false)

      try {
        const orderDetailsResponse = await AxiosInstance.post("appointment/add", { clinic_id, schedule_date, })
        const checkedorderId = orderDetailsResponse.data.data.appointment.id;
        Cookies.set('checkedorderId', checkedorderId,);
        setAppointment_id(checkedorderId)
        const response = await AxiosInstance.post("create-order-id-in-razorpay", { amount: selectedCharge });
        if (response.status === 400 && response.message === "Token is expired!!") {
          logOut();
          navigate("/login");
        } else {
          if (response.status === 200) {
            setSelectedCharge(null)
            setOrderId(response.data.data.order_id);
            const amount = response.data.data.amount
            // setShow3(true);
            const op = {
              key: 'rzp_test_3PYKIWLbUWhl67',
              accept_partial: false,
              amount: Math.round(selectedCharge * 100),
              currency: "INR",
              name: "Eugenix",
              description: "Test Transaction",
              image: "http://localhost/euginix_crm_backend/assets/front/images/logo.png",
              order_id: orderId,
              handler: async (res) => {
                if (res.razorpay_payment_id) {
                  try {
                    const checkedorderId = Cookies.get('checkedorderId');
                    const response = await AxiosInstance.post("add-payment-details", { table_id: checkedorderId, type_id: 1, payment_id: res.razorpay_payment_id, amount: selectedCharge })
                    if (response.data.status == 200) {
                      navigate('/appointments')
                      window.location.reload()
                    }

                  } catch (error) {

                  }
                }
              },
              notes: {
                permanent_address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#3399cc",
              },
            }

            const razorpay_key = process.env.RAZORPAY_KEY_ID;

            const rzpay = new razorpay(options);
            rzpay.open();
          }
        }

      } catch (error) {
        console.log(error)
      }
    }
  }


  useEffect(() => {
    const handleBookAppointmentClick = async () => {
      try {
        const response = await AxiosInstance.post("clinic/charges", { clinic_id: clinic_id })
        setCharges(response.data.data)
      } catch (error) {
        console.log(error)
      }
    };
    handleBookAppointmentClick()
  }, [])


  const getCurrentMonth = async (arg) => {
    try {
      const startDate = arg.view.currentStart;
      const response = await AxiosInstance.post('clinic/availability', { clinic_id: clinic_id, schedule_date: Moment(startDate).format("YYYY-MM") })
      console.log(response)
      const obj = Object.entries(response.data.data.events)
      obj.forEach(([key, value]) =>
        availabilityData.push({
          start: new Date(value),
          title: '',
          url: product_img,
        })
      )
      setEvents(availabilityData);
    } catch (error) {

    }


  }

  return (
    <div className='main-page bg4'>
      <DashboardHeader />
      <DashboardSidebar />

      <section className='main-section'>
        <div className='main-inner'>
        
          <DashboardTitle />

          <div className='provider-page-main'>

            <div className='box-title d-flex v-center'>
              <h3 className='mb-0'>Select a date for Appointment</h3>

              <div className='breadcrumb-block ml-auto'>
                <ul className='breadcrumb-list d-flex v-center'>
                  <li><Link to='/dashboard'>Home</Link></li>
                  <li><Link to='/location'>Location</Link></li>
                  <li>Date & Time</li>
                </ul>
              </div>
            </div>

            <div className='calendar-block'>
              <div className='white_box card-calendar'>
                <div className="calendar_style">
                  <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView='dayGridMonth'
                    datesSet={(arg) => getCurrentMonth(arg)}
                    events={events}
                    eventClick={handleEventClick}
                    editable={true}
                    eventContent={renderEventContent}
                  />
                </div>

                <Modal show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                  <Modal.Body>
                    <Modal.Title>Choose the option:</Modal.Title>
                    {
                      cahrges?.map((charge) => (
                        <>
                          <label className='radiobox time-radio mt-2' >
                            <Form.Control type='radio' name="Time" value={charge.charge}
                              checked={selectedCharge === charge.charge}
                              onChange={() => handleChargeChange(charge.charge)} />
                            <span className='checkmark'></span>{charge.type} ₹{charge.charge}
                          </label>
                          <br />
                        </>
                      ))
                    }
                    {chargerror && <p className="text-danger mt-3">Please select any option.</p>}
                    <div className='btnbox d-flex option-btn'>
                      <div className='btn-items'>
                        <Link className='btn primary mw_200' onClick={() => bookAnAppointment()}>Confirm Registration</Link>
                      </div>
                      <div className='btn-items' onClick={handleClose2}>
                        <button className='btn secondary mw_200' onClick={handleShow3} >Pay Registration fee</button>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  )
}
export default DateTime